import { useEffect, useMemo, useRef } from 'react';
import { transformScheduleData } from 'Helpers/scheduler';
import useAppStateStore from 'Store/AppState.store';
import { PAGE_SIZE } from 'Helpers/constants';
import { Store } from '@bryntum/calendar';
import useFetchData from 'Hooks/useFetchData';
import usePostData from 'Hooks/usePostData';

const postFiltersUrl = 'schedules/reports/view';
interface FetchSchedulerDataProps {
  formIds: string;
  hierarchyIds: string;
  dateStart?: string;
  dateEnd?: string;
  hierarchyTypes?: string[];
  page: number;
}

const formatDate = (date: string) => {
  const [month, day, year] = date.split('/');
  return `${year}-${month}-${day}`;
};

// Hook that provides the bryntum Store object used for the calendar
const useSchedulerData = ({
  formIds,
  hierarchyIds,
  dateStart,
  dateEnd,
  hierarchyTypes,
  page,
}: FetchSchedulerDataProps) => {
  const storeRef = useRef<Store>();

  const hierarchyFieldIds =
    hierarchyIds !== '' ? hierarchyIds?.split(',')?.map(Number) : [];

  const moduleFormIds = formIds !== '' ? formIds?.split(',')?.map(Number) : [];
  const startDate = dateStart ? formatDate(dateStart) : '';
  const endDate = dateEnd ? formatDate(dateEnd) : '';

  const { handleSetFilteringLoader, handleSetFilterHashKey } =
    useAppStateStore.getState();

  const isFiltering = useAppStateStore((state) => state.isFiltering);
  const hashKey = useAppStateStore((state) => state.filtersHashKey);

  const payload = {
    hierarchyFieldIds,
    moduleFormIds,
    startDate,
    endDate,
  };

  const { data: postData, isValidating: areFiltersSaving } = usePostData(
    postFiltersUrl,
    {},
    formIds !== '' && isFiltering ? payload : undefined // formId checks if a form has been selected before triggering this api request
  );

  useEffect(() => {
    if (!areFiltersSaving && postData?.hashKey) {
      handleSetFilterHashKey(postData.hashKey);
    }
  }, [postData, handleSetFilterHashKey, areFiltersSaving]);

  const schedulesDataUrl = hashKey
    ? `schedules/reports/view/${hashKey}?page=${page}&pageSize=${PAGE_SIZE}`
    : undefined;

  const { isValidating, data } = useFetchData(schedulesDataUrl);

  const storeData = useMemo(() => {
    return !isValidating && hashKey
      ? transformScheduleData(data?.items)
      : undefined;
  }, [data, isValidating, hashKey]);

  // 2. Transform API Data into a consumable Store data
  useEffect(() => {
    if (
      hashKey &&
      isFiltering &&
      !isValidating &&
      !areFiltersSaving &&
      storeData &&
      hierarchyTypes
    ) {
      storeRef.current = new Store({
        fields: ['description', 'date', ...hierarchyTypes],
        data: storeData,
        autoCommit: true,
        syncDataOnLoad: true,
      });

      // 3. Flip `isFiltering` State
      handleSetFilteringLoader(false);
    }
  }, [
    hashKey,
    isFiltering,
    isValidating,
    areFiltersSaving,
    storeData,
    hierarchyTypes,
    handleSetFilteringLoader,
  ]);

  return {
    store: storeRef.current,
    data,
    isLoading: isFiltering || isValidating || areFiltersSaving,
  };
};
export default useSchedulerData;
