import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import {
  calculateDates,
  findExistingIndexById,
  formatDate,
} from 'Helpers/helpers';
import { DEFAULT_LIST_MODE } from 'Helpers/constants';

type FilterType = {
  [key: string]: Array<Record<number, number[]>> | number[];
};

export type CalendarType = {
  dateStart?: string;
  dateEnd?: string;
  isCustomDates?: boolean;
};

type State = {
  _hasHydrated: boolean;
  tempFilters: FilterType;
  filters: FilterType;
  tempCalendar: CalendarType;
  calendar: CalendarType;
  mode: string;
  hierarchyTypes: string[] | undefined;
  isFiltering: boolean;
  isFiltersDirty: boolean;
  notifications: Record<string, string | number>[];
  filterFormIds: string;
  hierarchyIds: string;
  currentPageNumber: number;
  filtersHashKey: string;
  customDateRangeError: boolean;
};

type Action = {
  setHasHydrated: (state: boolean) => void;
  handleInitFilters: () => void;
  handleApplyFilters: () => void;
  handleSetSelectedFilters: (
    filterType: string,
    value: any,
    singleValue?: boolean
  ) => void;
  handleSetCalendarDates: (states: State['calendar']) => void;
  handleRemoveSelectedFilters: (filterType: string, id: number) => void;
  handleClearFilters: () => void;
  handleSetFilteringLoader: (value?: boolean) => void;
  handleSetCalendarMode: (mode: string) => void;
  handleSetHierarchyTypes: (types: string[]) => void;
  handleSetNotifs: (error: Record<string, string | number> | null) => void;
  handleSetCurrentPageNumber: (page: number) => void;
  handleSetFilterHashKey: (key: string) => void;
  hasSelectedForm: () => boolean;
  handleSetCustomDateRangeError: (key: boolean) => void;
};

// define the initial state
const initialState: State = {
  _hasHydrated: false,
  tempFilters: {},
  filters: {
    dateRange: [6],
    forms: [],
    hierarchy: [],
    recordType: [1],
  },
  tempCalendar: {},
  calendar: {
    dateStart: '',
    dateEnd: '',
    isCustomDates: false,
  },
  mode: DEFAULT_LIST_MODE,
  isFiltering: false,
  isFiltersDirty: false,
  notifications: [],
  filterFormIds: '',
  hierarchyIds: '',
  filtersHashKey: '',
  hierarchyTypes: undefined,
  currentPageNumber: 1,
  customDateRangeError: false,
};

const initialDates = {
  dateStart: formatDate(calculateDates(6).fromDate),
  dateEnd: formatDate(calculateDates(6).toDate),
};

const useAppStateStore = create<State & Action>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        setHasHydrated: (state) => {
          set({
            _hasHydrated: state,
          });
        },
        hasSelectedForm: () => {
          return get().tempFilters.forms.length > 0;
        },
        handleInitFilters: () => {
          set((state: State) => {
            const { dateRange } = get().filters;
            const isCustomDates = +dateRange === 0;
            const calendar = {
              ...state.calendar,
              isCustomDates,
            };

            if (!isCustomDates) {
              if (+dateRange === 6) {
                Object.assign(calendar, initialDates);
              } else {
                const { fromDate, toDate } = calculateDates(+dateRange);
                calendar.dateStart = formatDate(fromDate);
                calendar.dateEnd = formatDate(toDate);
              }
            }

            return {
              tempFilters: state.filters,
              calendar,
              tempCalendar: calendar,
              filterFormIds: state.filters.forms
                .map((form) => Array.isArray(form) && form[1])
                .join(','),
              hierarchyIds: state.filters.hierarchy
                .map((hierarchy) => Array.isArray(hierarchy) && hierarchy[1])
                .join(','),
              filtersHashKey: state.filtersHashKey,
              customDateRangeError: state.customDateRangeError,
              isFiltering: state.filters.forms.length > 0,
            };
          });
        },
        handleApplyFilters: () => {
          const formIds = get()
            .tempFilters.forms.map((form) => Array.isArray(form) && form[1])
            .join(',');
          const hierarchyIds = get()
            .tempFilters.hierarchy.map(
              (hierarchy) => Array.isArray(hierarchy) && hierarchy[1]
            )
            .join(',');

          set((state: State) => ({
            filters: state.tempFilters,
            calendar: state.tempCalendar,
            customDateRangeError: state.customDateRangeError,
            isFiltering: get().hasSelectedForm(),
            isFiltersDirty: !state.isFiltersDirty,
            filterFormIds: formIds,
            hierarchyIds,
            currentPageNumber: 1,
          }));
        },
        handleSetCalendarDates: (value) => {
          set((state: State) => ({
            tempCalendar: {
              ...state.tempCalendar,
              ...value,
            },
            isFiltersDirty: get().hasSelectedForm(),
          }));
        },
        handleSetSelectedFilters: (filterType, value, singleValue = false) => {
          const filtersArr = get().tempFilters[filterType];
          let existingFilterIndex: number;
          let filters: typeof filtersArr = value;

          if (filtersArr) {
            existingFilterIndex = findExistingIndexById(filtersArr, value[0]);
            filters = structuredClone(filtersArr);

            // Check if existing parent filter ID exists
            // If selected filter ID exists, update, otherwise add.
            if (singleValue) {
              filters = value;
            } else if (existingFilterIndex !== -1) {
              filters.splice(existingFilterIndex, 1, value);
            } else {
              filters = [...filters, value];
            }
          }

          // Set new filters
          set((state: State) => ({
            tempFilters: {
              ...state.tempFilters,
              [filterType]: filters,
            },
            isFiltersDirty: filterType === 'forms' || get().hasSelectedForm(),
          }));
        },
        handleRemoveSelectedFilters: (filterType, id) => {
          const filtersArr = get().tempFilters[filterType];
          const existingFilterIndex = findExistingIndexById(filtersArr, id);

          // If selected filter ID exists, remove.
          if (existingFilterIndex !== -1) {
            const filters = structuredClone(filtersArr);
            filters.splice(existingFilterIndex, 1);

            set((state: State) => ({
              tempFilters: {
                ...state.tempFilters,
                [filterType]: filters,
              },
              isFiltersDirty:
                filterType === 'forms' ? filters.length > 0 : true,
            }));
          }
        },
        handleClearFilters: () => {
          set(() => ({
            tempFilters: initialState.filters,
            tempCalendar: {
              ...initialState.calendar,
              ...initialDates,
            },
            filtersHashKey: '',
            isFiltersDirty: false,
          }));
        },
        handleSetFilteringLoader: (value) => {
          set((state: State) => ({
            isFiltering: value ?? !state.isFiltering,
          }));
        },
        handleSetCalendarMode: (mode) => {
          set(() => ({
            mode,
          }));
        },
        handleSetHierarchyTypes: (types) => {
          set(() => ({
            hierarchyTypes: types,
          }));
        },
        handleSetNotifs: (error) => {
          set((state: State) => ({
            notifications: error ? [...state.notifications, error] : [],
          }));
        },
        handleSetCurrentPageNumber: (page) => {
          set(() => ({
            currentPageNumber: page,
          }));
        },
        handleSetFilterHashKey: (key) => {
          set(() => ({
            filtersHashKey: key,
          }));
        },
        handleSetCustomDateRangeError: (key) => {
          set(() => ({
            customDateRangeError: key,
          }));
        },
      }),
      {
        name: 'app-state',
        partialize: ({
          filters,
          calendar,
          mode,
          hierarchyTypes,
          filtersHashKey,
          customDateRangeError,
        }) => ({
          filters,
          calendar,
          mode,
          hierarchyTypes,
          filtersHashKey,
          customDateRangeError,
        }),
        onRehydrateStorage: () => (state) => {
          state?.setHasHydrated(true);
        },
      }
    )
  )
);

export default useAppStateStore;
